// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/chun/Development/workspace/flyacademia-home/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("/home/chun/Development/workspace/flyacademia-home/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-opencv-js": () => import("/home/chun/Development/workspace/flyacademia-home/src/pages/opencv.js" /* webpackChunkName: "component---src-pages-opencv-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/chun/Development/workspace/flyacademia-home/.cache/data.json")

